<template>
  <div id="award-type-list">
    <v-overlay :value="OverlayLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-card>
      <v-app-bar
        color="primary"
        dense
        dark
        elevate-on-scroll
        scroll-target="#scrolling-techniques-7"
      >
        <v-toolbar-title>{{ PageTitle }}</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-app-bar>
      <v-card-text>
        <v-form
          ref="form1"
          v-model="valid1"
          lazy-validation
          v-on:submit.prevent="searchForm"
        >
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>

          <v-container
            v-if="recordData.YearwiseMembershipId != ''"
            class="mt-0"
          >
            <v-row wrap>
              <v-col cols="12" sm="12" md="12" lg="12" align="center">
                <v-img
                  v-if="recordData.MemberImagePath != ''"
                  :src="recordData.MemberImagePath"
                  :alt="recordData.MemberName"
                  lazy-src="/no-image-available.png"
                  max-width="100px"
                >
                </v-img>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="3">
                <h6>Membership Id</h6>
                <h6 class="text-dark">{{ recordData.MembershipId }}</h6>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="3">
                <h6>Member Name</h6>
                <h6 class="text-dark">{{ recordData.MemberName }}</h6>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="3">
                <h6>Member Mobile No</h6>
                <h6 class="text-dark">{{ recordData.MobileNo }}</h6>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="3">
                <h6>Member Email Id</h6>
                <h6 class="text-dark">{{ recordData.EmailId }}</h6>
              </v-col>
              <v-col cols="12" sm="12" md="12" lg="6">
                <h6>Member Address</h6>
                <h6 class="text-dark">{{ recordData.AddressTxt }}</h6>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="3">
                <h6>Member Zone</h6>
                <h6 class="text-dark">{{ recordData.ZoneName }}</h6>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="3">
                <h6>Member Lom</h6>
                <h6 class="text-dark">{{ recordData.LomName }}</h6>
              </v-col>

              <v-col cols="12" sm="6" md="6" lg="3">
                <h6>Payment Id</h6>
                <h6 class="text-dark">{{ recordData.PaymentRzrId }}</h6>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="3">
                <h6>Payment Type</h6>
                <h6 class="text-dark">{{ recordData.OrderTypeTxt }}</h6>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="3">
                <h6>Paid Date</h6>
                <h6 class="text-dark">{{ recordData.PaidDateTxt }}</h6>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="3">
                <h6>Payment Mode</h6>
                <h6 class="text-dark">{{ recordData.PaymentMode }}</h6>
              </v-col>
              <v-col cols="12" sm="6" md="6" lg="3">
                <h6>Paid Amount</h6>
                <h6 class="text-dark">{{ recordData.DepositedAmount }}</h6>
              </v-col>
            </v-row>
          </v-container>

          <v-container class="py-0">
            <h5 class="text-center text-dark">Change Lom Details</h5>
            <v-row wrap>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6><span class="text-danger">*</span> Zone</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  :loading="ZoneCodeOptionsLoading"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6">
                <h6><span class="text-danger">*</span> Lom</h6>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :items="LomCodeOptions"
                  :rules="LomCodeRules"
                  :loading="LomCodeOptionsLoading"
                  v-model="LomCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" lg="4" md="6" class="text-center">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="confirmAlert"
                  :loading="SubmitFlag"
                  elevation="30"
                  medium
                  color="primary"
                  class="font-size-h6 px-4 mt-7 white--text"
                >
                  Submit
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          @click="closePrompt"
          elevation="30"
          medium
          color="#a52a2a"
          class="font-size-h6 px-4 mr-3 white--text"
        >
          Close
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </div>
</template>
  
  <script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    editRecordPrompt: {
      required: true,
      type: Boolean,
    },
    recordData: {
      required: true,
    },
  },
  data() {
    return {
      valid1: true,
      search: "",
      uploadPercentage: 0,
      SubmitFlag: false,
      SearchFlag: false,
      ResultFlag: false,

      OverlayLoadingFlag: false,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      LomCodeRules: [(v) => !!v || "Lom is required"],
      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      rows: [],

      PageInfo: {},
      PageTitle: "Edit Senior Member Lom",
      PageDescription: "",
      CurrentYearId: 0,
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
      FloatingButtonOptions: {},
    };
  },
  computed: {},
  watch: {
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions called");
      this.ZoneCodeOptionsLoading = false;
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions called");
      this.LomCodeOptionsLoading = false;
    },
    ZoneCode: function () {
      console.log("watch Zone called");
      this.LomCode = "";
      this.getLomCodeOptions();
    },
  },
  created() {},
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;

      this.getZoneCodeOptions();
      console.log("recordData = " + JSON.stringify(this.recordData));
      this.LoadingFlag = false;
    },
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideEditRecordPrompt", this.ResultFlag);
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      this.ZoneCodeOptionsLoading = true;
      var selectbox1_source = "ZoneCode";
      var selectbox1_destination = "ZoneCodeOptions";
      var selectbox1_url = "api/year-wise-lom/zone-options";
      var selectbox1_conditions_array = {
        UserInterface: 4,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      var ZoneCode = this.ZoneCode;
      console.log({ ZoneCode });
      if (ZoneCode != "") {
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom/lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 2,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        message += "Zone should not be empty. ";
      }
    },
    confirmAlert() {
      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        Swal.fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.submitForm();
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        // this.toast("error", "Kindly fill the required fields", true);
      }
    },
    submitForm() {
      console.log("submitForm called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      var tr = this.recordData;
      console.log("tr = " + JSON.stringify(tr));

      if (validate1) {
        this.LoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/senior-members/lom-update";
        var upload = {
          UserInterface: 1,
          Member: tr.MemberId,
          YearwiseMembershipId: tr.YearwiseMembershipId,
          Zone: this.ZoneCode,
          Lom: this.LomCode,
          YearCode: tr.YearCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.LoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.ResultFlag = true;
              thisIns.sweetAlert("success", output, false);
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        var message = "Kindly fill the required fields";
        this.sweetAlert("error", message, false);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
  
  <style lang="scss"></style>
  